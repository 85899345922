// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../../libs/shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".account-billing_root_3568a02b {\n    margin-top: 56px;\n    width: 100%;\n}\n\n.account-billing_error_6a3514e5 {\n    color: var(--rowlfRed);\n}\n\nh5.account-billing_sectionHeader_9240e971 {\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n.account-billing_lapsed_82e48fa2 {\n    background: var(--cheshirePink);\n    padding: 4px 8px;\n    border-radius: 4px;\n}\n\n.account-billing_margin_04162fbd {\n    margin-top: 24px;\n}\n\n.account-billing_invoices_6875a9b6 {\n    width: 100%;\n    margin-top: 8px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n    .account-billing_invoices_6875a9b6 {\n        width: unset;\n        margin-top: unset;\n    }\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + " {\n    .account-billing_root_3568a02b {\n        width: var(--w7);\n    }\n}", "",{"version":3,"sources":["webpack://src/user/view/account-wrapper/account-billing/account-billing.css"],"names":[],"mappings":"AAEA;IACI,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,+BAA+B;IAC/B,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI;QACI,YAAY;QACZ,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["@value tablet, desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.root {\n    margin-top: 56px;\n    width: 100%;\n}\n\n.error {\n    color: var(--rowlfRed);\n}\n\nh5.sectionHeader {\n    align-items: center;\n    flex-wrap: wrap;\n}\n\n.lapsed {\n    background: var(--cheshirePink);\n    padding: 4px 8px;\n    border-radius: 4px;\n}\n\n.margin {\n    margin-top: 24px;\n}\n\n.invoices {\n    width: 100%;\n    margin-top: 8px;\n}\n\n@media tablet {\n    .invoices {\n        width: unset;\n        margin-top: unset;\n    }\n}\n\n@media desktop {\n    .root {\n        width: var(--w7);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + "",
	"root": "account-billing_root_3568a02b",
	"error": "account-billing_error_6a3514e5",
	"sectionHeader": "account-billing_sectionHeader_9240e971",
	"lapsed": "account-billing_lapsed_82e48fa2",
	"margin": "account-billing_margin_04162fbd",
	"invoices": "account-billing_invoices_6875a9b6"
};
export default ___CSS_LOADER_EXPORT___;
