import { useEffect } from 'react';
import Intercom, { show, shutdown } from '@intercom/messenger-js-sdk';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { INTERCOM_ID, QF_URL } from '@pumpkincare/config';
import { QUOTE_ID_COOKIE_NAME } from '@pumpkincare/quotes';
import {
  CAT,
  DOG,
  FORMATTED_WELLNESS_PHONE_NUMBER,
  removeCookie,
  useBooleanInput,
  WELLNESS_PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import {
  ButtonStyles,
  ChevronRight,
  LegalBody,
  Link,
  Spinner,
} from '@pumpkincare/shared/ui';
import {
  getPetsByStatus,
  getUserPets,
  PetCard,
  useUserAllPetPhotos,
  useUssr,
} from '@pumpkincare/user';

import MemberCenterBanners from '../member-center-banners';

import styles from './member-pets.css';

import catPlaceholder from '../shared/cat_placeholder_porkchopgray.png';
import dogPlaceholder from '../shared/dog_placeholder_porkchopgray.png';
import callout from './callout.png';

const PLACEHOLDER = { [DOG]: dogPlaceholder, [CAT]: catPlaceholder };

function setGladlyVisibility(visibility = '') {
  const gladlyWidget = document.querySelector('#gladlyChat_container');
  if (gladlyWidget) gladlyWidget.setAttribute('style', `visibility: ${visibility}`);
}

function MemberPets() {
  const [isAllPets, toggleAllPets] = useBooleanInput(false);
  const { punks1012SawWork } = useFlags();
  const { data: petsData, isFetching } = useUssr(getUserPets);
  const { data: userPetPhotos } = useUserAllPetPhotos();
  const { activePets, inactivePets } = getPetsByStatus(petsData);
  const hasActiveInsurance = activePets.some(pet => pet.policies?.length);

  const imageWrapperClassName = classNames({
    [styles.imageWrapper]: activePets.length < 3,
  });

  function openIntercom() {
    show();
    setGladlyVisibility('hidden !important');
  }

  useEffect(() => {
    // https://developers.intercom.com/installing-intercom/web/attributes-objects
    Intercom({
      app_id: INTERCOM_ID,
      hide_default_launcher: true,
    });

    function beforeUnload() {
      shutdown();
    }

    // in case user refreshes page, remove PawSupport
    window.addEventListener('beforeunload', beforeUnload);

    // in case user transitions to another member center page, remove PawSupport
    return () => {
      shutdown();
      window.removeEventListener('beforeunload', beforeUnload);
      setGladlyVisibility();
    };
  }, []);

  return (
    <>
      <MemberCenterBanners />

      <div className={styles.root}>
        <div className={styles.title}>
          <h5>All Pets</h5>
          <h2>All Pets</h2>
        </div>

        {isFetching ? (
          <Spinner classes={{ root: styles.spinner }} />
        ) : (
          <>
            <div className={styles.flexContainer}>
              {activePets.map(pet => {
                const petPhoto =
                  userPetPhotos?.find(item => item.pet_id === pet.id)?.location ||
                  PLACEHOLDER[pet.species];

                return (
                  <PetCard
                    key={pet.id}
                    pet={pet}
                    toPet={`/pets/${pet.id}/plan`}
                    toClaim={`/pets/${pet.id}/file-claim`}
                    petPhoto={petPhoto}
                    classes={{ imageWrapper: imageWrapperClassName }}
                  />
                );
              })}

              {isAllPets || !activePets.length
                ? inactivePets.map(pet => {
                    const petPhoto =
                      userPetPhotos?.find(item => item.pet_id === pet.id)
                        ?.location || PLACEHOLDER[pet.species];

                    return (
                      <PetCard
                        key={pet.id}
                        pet={pet}
                        toPet={`/pets/${pet.id}/plan`}
                        toClaim={`/pets/${pet.id}/file-claim`}
                        petPhoto={petPhoto}
                        classes={{ imageWrapper: imageWrapperClassName }}
                      />
                    );
                  })
                : null}
            </div>

            {inactivePets.length && activePets.length ? (
              <button
                className={classNames(styles.toggle, ButtonStyles.tertiary)}
                onClick={toggleAllPets}
              >
                {isAllPets ? 'Show Active Pets Only' : 'Show All Pets'}
                <ChevronRight
                  width={8}
                  height={14}
                  className={isAllPets ? styles.open : styles.closed}
                />
              </button>
            ) : null}
          </>
        )}

        <div className={styles.callout}>
          <img src={callout} alt='' />

          <LegalBody>
            {punks1012SawWork
              ? 'Get best-in-class pet insurance for the whole pack'
              : 'Get a 10% multi-pet discount for each additional pet you enroll'}
          </LegalBody>

          <Link
            href={QF_URL}
            className={ButtonStyles.secondary}
            onClick={() => removeCookie(QUOTE_ID_COOKIE_NAME)}
          >
            {!punks1012SawWork
              ? 'Add a Pet'
              : hasActiveInsurance
              ? 'Add a Pet - Get 10% Off'
              : 'Learn About Insurance'}
          </Link>

          {punks1012SawWork ? (
            <>
              <LegalBody isBold className={styles.saveText}>
                Want to save on routine care?
              </LegalBody>
              <LegalBody>
                Call{' '}
                <a
                  href={WELLNESS_PHONE_NUMBER_LINK}
                  style={{ textDecoration: 'none' }}
                >
                  {FORMATTED_WELLNESS_PHONE_NUMBER}
                </a>{' '}
                to learn about Pumpkin Wellness Club
              </LegalBody>
            </>
          ) : null}
        </div>
      </div>

      {punks1012SawWork ? (
        <div className={styles.pawsupport}>
          <div>
            <img
              src='/assets/images/icons/hospital-supplies/medical-bag.png'
              alt=''
            />
            <LegalBody isBold>PawSupport - 24/7 Pet Health Helpline</LegalBody>
            <button
              className={classNames(ButtonStyles.tertiary, styles.chat)}
              onClick={openIntercom}
            >
              Chat Now
              <ChevronRight width={10} height={16} />
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default MemberPets;
