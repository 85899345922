//TODO: consistent names for selectors
export const getUserSelector = state => state.user;
export const getUserPetsSelector = state => getUserSelector(state).pets;
export const getUserId = state => getUserSelector(state).id;
export const getUserExternalIdSelector = state => getUserSelector(state).externalId;
export const getUserRegistrationError = state => state.user.registrationError;
export const getUserRegistrationLoading = state => state.user.isRegistrationLoading;
export const getUserVetSelector = state => getUserSelector(state).vet;
export const getUserIdSelector = state => state.user.id;
export const getUserFirstName = state => state.user.firstName;
export const getUserLastName = state => state.user.lastName;
export const getUserEmail = state => state.user.email;
export const getUserIsChargedAnnually = state => state.user.is_charged_annually;
export const getUserTransactionFee = state => getUserSelector(state).transaction_fee;

export const getUserInvoices = state => getUserSelector(state).invoices;
export const getUserPolicies = state => getUserSelector(state).policies;
export const getUserLapsedSince = state => state.user.lapsedSince;
