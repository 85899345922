import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import {
  CAT,
  DOG,
  FORMATTED_PARENTHESES_PHONE_NUMBER,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body2, LegalBody, Spinner, Tabs, Typography } from '@pumpkincare/shared/ui';
import {
  getPetStatusCopy,
  getProductIsPending,
  useUserPetPhoto,
} from '@pumpkincare/user';

import Paths from '../../../app-shell/paths';
import usePetFromRoute from '../../hooks/use-pet-from-route';
import MemberCenterBanners from '../member-center-banners';
import PetClaimSubmission from './pet-claim-submission';
import PetClaimSuccess from './pet-claim-success';
import PetClaims from './pet-claims';
import PetCoverage from './pet-coverage';
import PetPhotoModal from './pet-photo-modal';
import PetRecords from './pet-records';

import styles from './pet-wrapper.css';

import catPlaceholder from '../shared/cat_placeholder_porkchopgray.png';
import dogPlaceholder from '../shared/dog_placeholder_porkchopgray.png';
import uploadIcon from './upload_icon_camera.png';

const PILLS = {
  Active: styles.active,
  'Renewal Declined': styles.renewalDeclined,
  Cancelled: styles.cancelled,
  'Billing Lapsed': styles.billingLapsed,
  Pending: styles.pending,
};

function getTabs(petId) {
  const coverage = Paths.PetCoverage.replace(/:petId/, petId);
  const claims = Paths.PetClaims.replace(/:petId/, petId);
  const records = Paths.PetRecords.replace(/:petId/, petId);

  return [
    {
      label: 'Plan',
      to: coverage,
      value: coverage,
    },
    {
      label: 'Claims',
      to: claims,
      value: claims,
    },
    {
      label: 'Records',
      to: records,
      value: records,
    },
  ];
}

const PLACEHOLDERS = {
  [DOG]: dogPlaceholder,
  [CAT]: catPlaceholder,
};

function PetWrapper() {
  const dispatch = useDispatch();
  const location = useLocation();

  const { pet, isPetFetching, pathPetId } = usePetFromRoute();

  const { data: userPetPhotoData, isFetching } = useUserPetPhoto(pathPetId);

  const petName = pet?.name;

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!isPetFetching && !petName) {
      dispatch(push(Paths.Landing));
    }
  }, [dispatch, isPetFetching, petName]);

  if (isPetFetching || !pet.id) {
    return (
      <div className={styles.padding}>
        <Spinner />
      </div>
    );
  }

  const isSawOnly = pet.wellness.length && !pet.policies.length;
  const activeProduct = isSawOnly ? pet.wellness : pet.policies;

  const petStatus = pet && getPetStatusCopy(activeProduct);
  const { isPending, isPendingAndFirstDay } = getProductIsPending(activeProduct);

  function handlePetIconClick() {
    setIsModalOpen(true);
  }

  function handleModalCancel() {
    setIsModalOpen(false);
  }

  function handleOnSave() {
    setIsModalOpen(false);
  }

  function renderHeader() {
    return (
      <>
        {isModalOpen ? (
          <PetPhotoModal
            placeHolderImage={PLACEHOLDERS[pet?.species]}
            petName={petName}
            petId={pathPetId}
            petPhoto={userPetPhotoData?.location}
            onCancel={handleModalCancel}
            onSave={handleOnSave}
          />
        ) : null}

        <div
          className={classNames(styles.header, {
            [styles.pendingHeader]: isPending,
          })}
        >
          <div className={styles.petIconContainer}>
            <div className={styles.petIconWrapper} onClick={handlePetIconClick}>
              {isFetching ? (
                <div className={styles.spinnerWrapper}>
                  <Spinner classes={{ root: styles.spinner }} />
                </div>
              ) : (
                <img
                  className={styles.petIcon}
                  src={userPetPhotoData?.location || PLACEHOLDERS[pet?.species]}
                  alt=''
                />
              )}
            </div>
            <img
              src={uploadIcon}
              alt=''
              className={styles.uploadIcon}
              onClick={handlePetIconClick}
            />
          </div>

          <div className={styles.title}>
            <h5>{petName}</h5>
            <h2>{petName}</h2>
            {pet.policies.length && pet.plans.length ? (
              <LegalBody className={PILLS[petStatus]}>{petStatus}</LegalBody>
            ) : null}
          </div>

          {!isPending ? (
            <>
              <Tabs
                tabsData={getTabs(pathPetId)}
                activeValue={location.pathname}
                classes={{
                  container: styles.tabContainer,
                  tabCopy: classNames(
                    Typography.body2,
                    Typography.bold,
                    styles.tabCopy
                  ),
                  tab: styles.tab,
                  activeCopy: styles.activeCopy,
                }}
              />
            </>
          ) : null}
        </div>
      </>
    );
  }

  return isPending ? (
    <div>
      <Switch>
        <Route path={Paths.PetCoverage}>
          <MemberCenterBanners />
          {renderHeader()}

          <div className={styles.warning}>
            {isPendingAndFirstDay ? (
              <Body2>
                Please hang tight while we process your account. Your enrollment
                information will typically be available the next business day. Check
                back soon!
              </Body2>
            ) : (
              <div>
                <Body2>We have found an issue with your account.</Body2>
                <Body2>
                  Please call{' '}
                  <a href={PHONE_NUMBER_LINK}>
                    {FORMATTED_PARENTHESES_PHONE_NUMBER}
                  </a>{' '}
                  to talk to our team to help.
                </Body2>
              </div>
            )}
          </div>
        </Route>
      </Switch>
    </div>
  ) : (
    <Switch>
      <Route path={Paths.PetCoverage}>
        <MemberCenterBanners />
        {renderHeader()}

        <PetCoverage />
      </Route>

      <Route path={Paths.PetClaims}>
        <MemberCenterBanners />
        {renderHeader()}

        <PetClaims />
      </Route>

      <Route path={Paths.PetRecords}>
        <MemberCenterBanners />
        {renderHeader()}

        <PetRecords />
      </Route>

      <Route path={Paths.PetClaimSubmission}>
        <PetClaimSubmission />
      </Route>

      <Route path={Paths.PetClaimSuccess}>
        <PetClaimSuccess />
      </Route>

      <Route path={Paths.PetEstimateSubmission}>
        <PetClaimSubmission isEstimate />
      </Route>

      <Route path={Paths.PetEstimateSuccess}>
        <PetClaimSuccess isEstimate />
      </Route>

      {/* this is just for tests */}
      <Route path={Paths.PetsWrapper}>
        <MemberCenterBanners />
        {renderHeader()}
      </Route>
    </Switch>
  );
}

export default PetWrapper;
