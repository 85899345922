import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { formatCurrency, formatISODate } from '@pumpkincare/shared';
import { Body1, DocumentButton, LegalBody } from '@pumpkincare/shared/ui';

import styles from './claim-invoice.css';

const HEADER_TYPES = {
  insurance: 'Insurance',
  wellness: 'Wellness',
  prevent: 'Wellness',
};
export function formatSubclaimHeader(type) {
  return HEADER_TYPES[type] || 'N/A';
}

function ClaimInvoice(props) {
  const { createdAt, id, status, subClaims, summaryUrls } = props;
  const { punks1012SawWork } = useFlags();

  return (
    <div data-testid='claim-invoice' className={styles.container}>
      <div
        className={classNames(styles.header, {
          [styles.hasSubClaims]: subClaims.length,
        })}
      >
        <div className={styles.invoiceInfo}>
          <Body1 isBold>Claim #{id}</Body1>

          <LegalBody>
            Date Submitted: <b>{formatISODate(createdAt)}</b>
          </LegalBody>
        </div>

        <LegalBody className={styles.status}>{status}</LegalBody>
      </div>

      <div className={styles.subClaims}>
        {subClaims.map(claim => (
          <div className={styles.amounts} key={claim.id}>
            <LegalBody>
              <b>{punks1012SawWork ? formatSubclaimHeader(claim.type) : claim.id}</b>
            </LegalBody>

            <div className={styles.amount}>
              <LegalBody>Amount Claimed</LegalBody>
              <LegalBody isBold>
                {formatCurrency(claim.claimedAmount, { areCents: true })}
              </LegalBody>
            </div>

            <div className={styles.amount}>
              <LegalBody>Amount Paid</LegalBody>
              <LegalBody isBold>
                {status === 'Completed'
                  ? formatCurrency(claim.paidAmount, { areCents: true })
                  : ''}
              </LegalBody>
            </div>
          </div>
        ))}
      </div>

      {summaryUrls ? (
        <>
          {summaryUrls.length > 1 ? (
            <DocumentButton
              title={summaryUrls[1].label}
              href={summaryUrls[1].url}
              icon='download'
            />
          ) : null}

          <DocumentButton
            title={summaryUrls[0].label}
            href={summaryUrls[0].url}
            icon='download'
          />
        </>
      ) : null}
    </div>
  );
}

ClaimInvoice.propTypes = {
  createdAt: PropTypes.string,
  id: PropTypes.string,
  status: PropTypes.string,
  subClaims: PropTypes.array,
  summaryUrls: PropTypes.array,
};

ClaimInvoice.defaultProps = {
  createdAt: '',
  id: '',
  status: '',
  subClaims: [],
  summaryUrls: [],
};

export default ClaimInvoice;
