import { Route, Switch, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { Spinner, Tabs, Typography } from '@pumpkincare/shared/ui';
import { useUssr } from '@pumpkincare/user';

import Paths from '../../../app-shell/paths';
import MemberCenterBanners from '../member-center-banners';
import AccountBilling from './account-billing';
import AccountOverview from './account-overview';
import ReimbursementPage from './reimbursement-page';

import styles from './account-wrapper.css';

const BASE_TABS = [
  {
    label: 'Overview',
    to: Paths.Overview,
    value: Paths.Overview,
  },
  {
    label: 'Reimbursement',
    to: Paths.Reimbursement,
    value: Paths.Reimbursement,
  },
  {
    label: 'Billing',
    to: Paths.Billing,
    value: Paths.Billing,
  },
];

function AccountWrapper() {
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const { data: userData, isFetching: isUserFetching } = useUssr();

  if (isUserFetching) {
    return (
      <div className={styles.spinner}>
        <Spinner data-testid='user-spinner' />
      </div>
    );
  }

  return (
    <>
      <MemberCenterBanners />

      <div className={styles.header}>
        <div className={styles.title}>
          <h4>Account</h4>
          <h2>Account</h2>
        </div>

        <Tabs
          tabsData={BASE_TABS}
          activeValue={location.pathname}
          classes={{
            container: styles.tabContainer,
            tabCopy: classNames(Typography.body2, Typography.bold, styles.tabCopy),
            tab: styles.tab,
            activeCopy: styles.activeCopy,
          }}
        />
      </div>
      <div className={styles.root}>
        <Switch>
          <Route path={Paths.Overview}>
            <AccountOverview />
          </Route>

          <Route path={Paths.Reimbursement}>
            <ReimbursementPage />
          </Route>

          <Route path={Paths.NewBilling}>
            <AccountBilling />
          </Route>
        </Switch>
      </div>
    </>
  );
}

export default AccountWrapper;
