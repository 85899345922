/* eslint-disable react/jsx-no-target-blank */
import { useFlags } from 'launchdarkly-react-client-sdk';

import { WWW_URL } from '@pumpkincare/config';

import styles from './legal.module.css';

function Legal() {
  const { punks1012SawWork } = useFlags();

  return (
    <div className={styles.root}>
      <div className={styles.darkBlueWave} />

      <img
        className={styles.heartIcon}
        src='/assets/images/heart_scout_navy.svg'
        alt=''
      />

      <div className={styles.container}>
        <div>
          <div className={styles.link}>
            <a
              href={`${WWW_URL}/references/`}
              target='_blank'
              rel='noopener'
              className={styles.linkText}
            >
              SEE ALL REFERENCES
              <img src='/assets/images/iconImages/chevrons-right-white.svg' alt='' />
            </a>
          </div>

          <div className={styles.link}>
            <a
              href={`${WWW_URL}/insurer-disclosure`}
              rel='noopener'
              target='_blank'
              className={styles.linkText}
            >
              INSURER DISCLOSURE OF IMPORTANT POLICY PROVISIONS
              <img src='/assets/images/iconImages/chevrons-right-white.svg' alt='' />
            </a>
          </div>

          <div>
            {punks1012SawWork ? (
              <p className={styles.legalText}>
                Pumpkin Wellness Club is marketed and administered by Sprout Wellness
                Services LLC (d/b/a "Pumpkin Wellness Club"). Pumpkin Wellness Club
                is not an insurance policy. It is offered as a standalone pet
                wellness membership program. For full terms, visit{' '}
                <a
                  href={`${WWW_URL}/sample-plan/`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className={styles.legalLink}
                >
                  wellness.pumpkin.care/membership-agreement
                </a>
              </p>
            ) : null}

            <p className={styles.legalText}>
              Pumpkin Pet Insurance policies do not cover pre-existing conditions.
              Waiting periods, annual deductible, co-insurance, benefit limits and
              exclusions may apply. For full terms, visit{' '}
              <a
                href={`${WWW_URL}/sample-plan/`}
                target='_blank'
                rel='noopener'
                className={styles.legalLink}
              >
                pumpkin.care/insurancepolicy
              </a>
              . Products and rates may vary and are subject to change. Discounts may
              vary and are subject to change.{' '}
              <>
                Insurance products are underwritten by either Independence American
                Insurance Company (NAIC #26581. A Delaware insurance company
                headquarters located at 11333 N. Scottsdale Rd, Ste. 160, Scottsdale,
                AZ 85254), or United States Fire Insurance Company (NAIC #21113.
                Morristown, NJ). Please refer to your policy forms to determine the
                underwriter for your policy. Insurance is{' '}
              </>
              administered and produced by Pumpkin Insurance Services Inc.
              (“Pumpkin”) (NPN #19084749; Domiciled in New York with offices at 432
              Park Avenue South, Floor 12, New York, NY 10016; CA License #6001617).
              Pumpkin is a licensed insurance agency, not an insurer, and receives
              compensation based on the premiums for the insurance policies it sells.
              For more details, visit{' '}
              <a
                href={`${WWW_URL}/underwriting-information`}
                target='_blank'
                rel='noopener'
                className={styles.legalLink}
              >
                pumpkin.care/underwriting-information
              </a>
              . Pumpkin Preventive Essentials is not an insurance policy. It is
              offered as an optional add-on non-insurance benefit. Pumpkin is
              responsible for the product and administration. For full terms, visit{' '}
              <a
                href={`${WWW_URL}/sample-plan`}
                target='_blank'
                rel='noopener'
                className={styles.legalLink}
              >
                pumpkin.care/customeragreement
              </a>
              . Pumpkin Preventive Essentials is not available in all states.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Legal;
